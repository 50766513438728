const theme = {
  accentColor: '#7d76d6',
  accentColorHover: '#625ac4',
  brandColor: '#30F5C7',
  linkColor: '#6c4c97',
  textColor: '#6F6F6F',
  secondaryColor: '#009BA4',
  secondaryGradientColor: '#18C9B6',
  successColor: '#30F5C7',
  errorColor: '#f53757',
  bgLightColor: '#F6F6F6',
  breakpoint: {
    xs: '(max-width: 576px)',
    sm: '(min-width: 577px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
  },
};

export default theme;
