import React from 'react';
import { ThemeProvider } from 'styled-components';

import Footer from './Footer';
import { GlobalStyle } from '../styles/globalStyle';
import theme from '../styles/theme';

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {
    speed: 200,
  });
}

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      {children}
      <Footer />
    </>
  </ThemeProvider>
);

export default Layout;
