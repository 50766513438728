import styled from 'styled-components';

import imgQuote from '../images/bg-quote.svg';

const Slide = styled.div`
  padding: 0 50px;
  background-repeat: no-repeat;
  background-position: top left;
  cursor: grab;
  outline: 0;

  ${({ isQuote }) => {
    if (isQuote) return `background-image: url(${imgQuote});`;
  }}
`;

export default Slide;
