import PropTypes from 'prop-types';
import styled from 'styled-components';

const Heading = styled.h1`
  padding: 50px 0;
  background-image: -webkit-linear-gradient(
    222deg,
    ${({ theme }) => theme.brandColor} 8%,
    #18c9b6 48%,
    #009ba4 99%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
  text-align: ${({ align }) => align};

  @media ${({ theme }) => theme.breakpoint.sm} {
    font-size: 48px;
    line-height: 54px;
  }
`;

Heading.defaultProps = {
  align: 'center',
};

Heading.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Heading;
