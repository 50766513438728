import React from 'react';
import styled from 'styled-components';

import imgLogo from '../images/logo-hubble.svg';
import imgWave from '../images/bg-footer-wave.svg';
import icoFacebook from '../images/socialLinks/facebook.svg';
import icoLinkedin from '../images/socialLinks/linkedin.svg';
import icoInstagram from '../images/socialLinks/instagram.svg';

const Wrapper = styled.footer`
  position: relative;
  padding-top: 65px;
  padding-bottom: 1px;
  background-image: linear-gradient(
    222deg,
    ${({ theme }) => theme.brandColor} 8%,
    #18c9b6 48%,
    #009ba4 99%
  );
  color: #ffffff;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${imgWave});
    background-position: top -1px center;
    background-repeat: no-repeat;
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 10;
  margin-top: 60px;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 160px;
  display: inline-block;
`;

const Company = styled.div`
  display: none;
`;

const Address = styled.div`
  margin: 20px 0;
  font-style: normal;
`;

const FooterLink = styled.a`
  display: inline-block;
  margin-bottom: 6px;
  border-bottom: dotted 1px #fff;
  color: white;
  font-style: normal;
  text-decoration: none;
`;

const Province = styled.abbr`
  margin: 0 5px;
  text-decoration: none;
`;

const SocialLinksList = styled.div`
  margin: 20px 0;
`;

const SocialLink = styled.a`
  margin: 0 5px;
`;

const Copyrights = styled.div`
  margin-top: 30px;
  font-size: 0.8rem;
  opacity: 0.75;
`;

const Footer = () => (
  <Wrapper>
    <Container className="container">
      <div className="row">
        <div className="col-sm-12">
          <address className="vcard">
            <Logo src={imgLogo} alt="Logo Hubble" />

            <Company className="org fn">
              <div className="organization-name">Hubble SENC</div>
              <div className="url">https://teamhubble.com</div>
            </Company>

            <Address className="adr">
              <div className="street-address">
                1095 rue de l'Amérique-française
              </div>
              <div className="extended-address">App 516</div>
              <span className="locality">Québec</span>,
              <Province title="Québec" className="region">
                QC
              </Province>
              <span className="postal-code">G1R 5P9</span>
            </Address>

            <FooterLink className="tel" href="tel:+14182614339">
              418 261-4339
            </FooterLink>
            <br />
            <FooterLink
              href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#116;&#101;&#097;&#109;&#104;&#117;&#098;&#098;&#108;&#101;&#046;&#099;&#111;&#109;"
              className="email"
              target="_blank"
              rel="noopener noreferrer"
            >
              hello@teamhubble.com
            </FooterLink>
          </address>

          <SocialLinksList>
            <SocialLink
              href="https://www.facebook.com/teamhubble"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icoFacebook} alt="Facebook" title="Facebook" />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/11088034"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icoLinkedin} alt="Linkedin" title="Linkedin" />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/teamhubble"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icoInstagram} alt="Instagram" title="Instagram" />
            </SocialLink>
          </SocialLinksList>

          <Copyrights>© Hubble SENC, 2019 - Tous droits réservés</Copyrights>
        </div>
      </div>
    </Container>
  </Wrapper>
);

export default Footer;
